<template>
  <validation-observer
    ref="accountRules"
    tag="form"
  >
    <b-row>
      <!-- Facebook -->
      <b-col md="6">
        <validation-provider
          #default="validationContext"
          name="Facebook"
        >
          <b-form-group
            label="Facebook"
            label-for="facecbook"
          >
            <b-form-input
              id="facecbook"
              v-model="inpoDetails.social_facebook"
              :state="getValidationState(validationContext)"
              trim
              placeholder="Facebook Link"
            />
            <b-form-invalid-feedback>
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
      </b-col>

      <!-- Twitter -->
      <b-col md="6">
        <validation-provider
          #default="validationContext"
          name="Twitter"
        >
          <b-form-group
            label="Twitter"
            label-for="twitter"
          >
            <b-form-input
              id="twitter"
              v-model="inpoDetails.social_twitter"
              :state="getValidationState(validationContext)"
              trim
              placeholder="Twitter Link"
            />
            <b-form-invalid-feedback>
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
      </b-col>

      <!-- Youtube -->
      <b-col md="6">
        <validation-provider
          #default="validationContext"
          name="Youtube"
        >
          <b-form-group
            label="Youtube"
            label-for="youtube"
          >
            <b-form-input
              id="youtube"
              v-model="inpoDetails.social_youtube"
              :state="getValidationState(validationContext)"
              trim
              placeholder="Youtube Link"
            />
            <b-form-invalid-feedback>
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
      </b-col>

      <!-- Instagram -->
      <b-col md="6">
        <validation-provider
          #default="validationContext"
          name="Instagram"
        >
          <b-form-group
            label="Instagram"
            label-for="instagram"
          >
            <b-form-input
              id="instagram"
              v-model="inpoDetails.social_instagram"
              :state="getValidationState(validationContext)"
              trim
              placeholder="Instagram Link"
            />
            <b-form-invalid-feedback>
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
      </b-col>

      <!-- Tags -->
      <b-col md="6">
        <validation-provider
          #default="validationContext"
          name="Tags"
        >
          <b-form-group
            label="Tags"
            label-for="tags"
          >
            <v-select
              v-model="inpoDetails.tags"
              multiple
              label="tag_name"
              :reduce="tag => tag.id"
              :options="tagOptions"
            />
            <b-form-invalid-feedback>
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
      </b-col>

      <!-- Language -->
      <b-col md="6">
        <validation-provider
          #default="validationContext"
          name="Language"
        >
          <b-form-group
            label="Language"
            label-for="language"
          >
            <v-select
              id="language"
              v-model="inpoDetails.languages"
              multiple
              :reduce="lang => lang.id"
              label="language_name"
              :options="options"
            />
            <b-form-invalid-feedback>
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
        <!-- <validation-provider
          v-if="inpoDetails.languages.includes('other')"
          #default="validationContext"
          name="Other Language"
        >
          <b-form-group
            label="Other Language"
            label-for="other-language"
          >
            <b-form-input
              id="other-language"
              v-model="inpoDetails.otherLanguage"
              :state="getValidationState(validationContext)"
              trim
              placeholder="Other Language"
            />
            <b-form-invalid-feedback>
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider> -->
      </b-col>

      <!-- Donation Category -->
      <b-col md="6">
        <validation-provider
          #default="validationContext"
          name="Donation Category"
        >
          <b-form-group
            label="Donation Category"
            label-for="donation-category"
          >
            <v-select
              multiple
              id="donation-category"
              v-model="inpoDetails.donation_categories"
              label="category_name"
              :reduce="d => d.id"
              :options="donationCategories"
            />
            <b-form-invalid-feedback>
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
        <!-- <validation-provider
          v-if="inpoDetails.donation_categories.includes('other')"
          #default="validationContext"
          name="Other Category"
        >
          <b-form-group
            label="Other Category"
            label-for="other-category"
          >
            <b-form-input
              id="other-category"
              v-model="inpoDetails.otherDonationCategories"
              :state="getValidationState(validationContext)"
              trim
              placeholder="Other Category"
            />
            <b-form-invalid-feedback>
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider> -->
      </b-col>
      <!-- <b-col md="6" />
      <b-col
        cols="12"
        class="pt-1"
      >
        <div class="payment">
          <h5>Multiple Emails and Numbers</h5>
        </div>
      </b-col>

      <b-col
        v-for="(e, index) in inpoDetails.multi"
        :key="index"
        md="6"
      >
        <validation-provider
          #default="validationContext"
          name="Email"
          rules="required|email"
        >
          <b-form-group
            label="Email"
            label-for="email"
          >
            <b-form-input
              id="email"
              v-model="e.email"
              :state="getValidationState(validationContext)"
              trim
            />

            <b-form-invalid-feedback>
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
        <validation-provider
          #default="validationContext"
          name="Email"
        >
          <b-form-group
            label="Phone"
            label-for="phone"
          >
            <b-form-input
              id="phone"
              v-model="e.phone"
              :state="getValidationState(validationContext)"
            />

            <b-form-invalid-feedback>
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
      </b-col>

      <b-col
        cols="12"
        class="mt-50"
      >
        <b-button
          variant="primary"
          class="mr-1"
          @click="addMulti"
        >
          Add New
        </b-button>
      </b-col> -->

    </b-row>
  </validation-observer>

</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import vSelect from 'vue-select'
import {
  BFormGroup, BFormInput, BFormInvalidFeedback, BFormSelect, BCol, BRow, BButton,
} from 'bootstrap-vue'
import { ref } from '@vue/composition-api'
import formValidation from '@core/comp-functions/forms/form-validation'
import { required, alphaNum, email } from '@validations'
import VueSweetalert2 from 'vue-sweetalert2'
import Vue from 'vue'

Vue.use(VueSweetalert2)

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    vSelect,
    ValidationProvider,
    ValidationObserver,
    // eslint-disable-next-line vue/no-unused-components
    BFormSelect,
    // eslint-disable-next-line vue/no-unused-components
    BButton,
  },
  props: {
    inpoDetails: {
      type: Object,
      default: () => {},
    },
    options: {
      type: Array,
      default: () => [],
    },
    donationCategories: {
      type: Array,
      default: () => [],
    },
    tagOptions: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      required,
      alphaNum,
      email,
    }
  },
  setup() {
    const variant = ref('dark')
    const option = ref(['Canada', 'United States'])
    const selected = ref(null)
    const selectedCategory = ref(null)
    const selectedPayment = ref(null)

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation()

    return {
      refFormObserver,
      getValidationState,
      resetForm,
      variant,
      option,
      selected,
      selectedCategory,
      selectedPayment,
    }
  },
  methods: {
    addMulti() {
      this.inpoDetails.multi.push({ email: '', phone: '' })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-wizard.scss';
@import '@core/scss/vue/libs/vue-select.scss';

</style>
